.filtersScreen {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: var(--bgcolor);
  margin: 20px;
}

.filtersHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.filtersTitle {
  padding-right: 10px;
  /* font-size: 15px; */
  color: var(--darkgrey);
  font-weight: bold;
  text-align: start;
  font-size: 1.2rem;
}

.filtersBody {
  /* height: 100%; */
  padding: 0px 0px 60px 0px;
  display: flex;
  flex-direction: column;
}

.oneFilterBody {
  margin-bottom: 20px;
}

.oneFilterTitle {
  text-align: left;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--darkgrey);
  margin-bottom: 10px;
}

.oneFilterAllOptionsFrame {
  display: flex;
  justify-content: left;
  align-items: center;
  color: var(--darkgrey);
  margin-left: 10px;
}

.oneFilterOption {
  display: flex;
  align-items: center;
  color: var(--darkgrey);
  cursor: pointer;
}


/* FOOTER  */

.filtersFooterFrame {
  position: fixed;
  height: 60px;
  bottom: 0%;
  left: 0%;
  right: 0%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
  background-color: var(--bgcolor);
  z-index: 2;
}

.footerButtons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.resultsAmount {
  margin-left: 20px;
  color: var(--textcolor);
}
