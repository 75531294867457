/*
PARENT

display: flex
flex-direction: row | column
flex-wrap: nowrap | wrap  // si corta o los pone en otra linea

justify-content: 
  flex-start | 
  flex-end | 
  center | 
  space-between | // sin margen a los extremos
  space-around | // con medio margen al extremo
  space-evenly  // con mismo margen al extremo

align-items: // similar a justify-content pero en el otro eje
  stretch |  // fill container
  flex-start | 
  flex-end | 
  center |

align-content: // en caso de "wrap" esto define como se mueven las lineas extras 
  flex-start | 
  flex-end | 
  center | 
  space-between | 
  space-around | 
  space-evenly | 
  stretch


CHILD

flex: (flex-grow flex-shrink flex-basis)
*/

.discountScreen {
  display: flex;
  flex-direction: column;
  /* background-color: var(--backgroundBackground); it is set already in index.html*/ 
  height: '100%';
  
}