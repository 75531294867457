.mapScrollableArea {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid black; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  margin: 0px 0px 10px 0px;
  transition: transform 0.3s ease-in-out;
}

.mapDiscountCard {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-width: 550px;
  /* width: 100%; */
  margin: 0px 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.mapDiscountHeaderFrame {
  /* frame to put images horizontally and scrollable */
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  /* flex-direction: space-around; */
  position: relative;
  width: 100%;
  height: 140px;
  border-radius: 4px 0px 0px 0px;
  background-color: var(--neutralWhite);  
  /* background-image: linear-gradient(to right, red , yellow); */
}


.mapHeaderImage {
  /* position: relative; */
  object-fit: cover;
  /* width: 70%; */
  margin-right: 2px;
  height: 100%;
  /* min-width: 20%; */
  background-image: linear-gradient(
    /* to bottom right */
    to right, 
    #e7e7e7 30%, 
    #d1d1d1 50%, 
    #e7e7e7 60%);

  background-size: 300%;
  animation-name: loadingBgAnimation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes loadingBgAnimation {
  0% { background-position: 100% 0%}
  100%{background-position: 0% 0%}
}


.mapDiscountBodyFrame {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  position: relative;
  margin: 10px;
}

.mapCloseCardButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  /* background-color: transparent grey */
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--neutralWhite);
  border-radius: 100%;
  position: absolute;
  left: 6px;
  top: 6px;
}


.mapDiscountScrollableButtonFrame {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  /* width: 100%; */
  height: 42px;
  margin-right: -16px;  
  padding-right: 16px;
  justify-content: flex-start;
  gap: 4px;
}

.mapDiscountButtonText {
  font-weight: bold;
  margin-left: 8px;
  white-space: nowrap;
}