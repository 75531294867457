.full-screen {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--backgroundBigScreen);
}

.holder {
  width: 100%;
  display: flex;
  background-color: var(--backgroundBackground);
  max-width: 950px;
}

.main-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.body-days {
  flex: 1;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px; /* added */
}

.body {
  flex: 1;
  display: flex;
  flex-direction: row;
  /* wrap them */
  flex-wrap: wrap;
  justify-content: center;
  gap: 2px;
  column-gap: 10px;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
}
