@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css?family=Lato");

/*
PARENT

display: flex
flex-direction: row | column
flex-wrap: nowrap | wrap  // si corta o los pone en otra linea

justify-content: 
  flex-start | 
  flex-end | 
  center | 
  space-between | // sin margen a los extremos
  space-around | // con medio margen al extremo
  space-evenly  // con mismo margen al extremo

align-items: // similar a justify-content pero en el otro eje
  stretch |  // fill container
  flex-start | 
  flex-end | 
  center |

align-content: // en caso de "wrap" esto define como se mueven las lineas extras 
  flex-start | 
  flex-end | 
  center | 
  space-between | 
  space-around | 
  space-evenly | 
  stretch


CHILD

flex: (flex-grow flex-shrink flex-basis)
*/

:root {
  /*v1*/
  --bgcolor: white;
  --alttextcolor: white;
  --maincolor: #3871e0;
  --maincolor2: #3870e0ec;
  --titlecolor: #202124;
  --darkgrey: #4b4f52;
  --textcolor: #717579;
  --lightgrey: #c4c4c4;
  --holdercolor: #eeeeee;
  --popupbgcolor: rgba(0, 0, 0, 0.336);
  
  /*v2*/
  
  /* PRIMARY */
  --primaryPrimary: #2454F8;  /* #3871e0; */
  --primary20Black: #1D43C6;
  --primary40Black: #163295;
  --primary60Black: #0E2263;
  --primary80Black: #071132;
  --primary20White: #5076F9;
  --primary40White: #7C98FB;
  --primary60White: #A7BBFC;
  --primary80White: #D3DDFE;
  --primary90White: #E9EEFE;
  /* SECONDARY */
  --secondarySecondary: #727694;
  --secondary40White: #AAADBF;
  --secondary40Black: #444759;
  /* NEUTRAL */
  --neutralBlack: #040819;
  --neutral50White: #82848C;
  --neutral70White: #B4B5BA;
  --neutral90White: #E6E6E8;
  --neutralWinterWhite: #F5F7F9;
  --neutralWhite: #FFFFFF;
  /* BACKGROUND */
  --backgroundBackground: #F5F7F9;
  --backgroundBigScreen: #FFFFFF;

  /* COMPLEMENTARY */
  --complementaryBackground: #FFFFFF;
  --complementaryFeedback: #20C8FE;
}

/*v1*/
* {
  font-family: "Nunito";
}

p {
  margin: 0px;
}
/*v2*/

/* Mobile Styles */
@media screen and (max-width: 599px) {
  /* H1 */
  h1 {
    font-weight: 800;
    font-size: 40px;
    line-height: 140%;
  }

  /* H2 */
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }

  p {
    font-size: 14px;
  }

  /* Subtitle 1 */
  .subtitle-1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }

  /* Subtitle 2 */
  .subtitle-2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }

  /* Subtitle 3 */
  .subtitle-3 {
    font-weight: 700;
    font-size: 14px;  /* figma sale 12 */
    line-height: 140%;
  }

  /* Body 1 */
  .body-1 {
    font-weight: 400;
    font-size: 15px; /* figma sale 14 */
    line-height: 140%;
  }

  /* Body 2 */
  .body-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
  }

  /* Body 3 */
  .body-3 {
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
  }

  /* Button 1 */
  .button-1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }

  /* Button 2 */
  .button-2 {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }
}

/* Tablet Styles */
/* Segun figma es @media screen and (min-width: 600px) and (max-width: 1024px) { 
  pero a mi no me gusto asi que lo voy a sacar haciendo el min width enorme */
@media screen and (min-width: 600px) {
  /* H1 - Title */
  h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 140%;
  }
  
  /* H2 - Title */
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
  }

  h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }

  p {
    font-size: 16px;
  }
  
  /* Subtitle 1 */
  .subtitle-1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }
  
  /* Subtitle 2 */
  .subtitle-2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }
  
  /* Subtitle 3 */
  .subtitle-3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }
  
  /* Body 1 */
  .body-1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
  
  /* Body 2 */
  .body-2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
  
  /* Body 3 */
  .body-3 {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
  }
  
  /* Button 1 */
  .button-1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }
  
  /* Button 2 */
  .button-2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
}

/* Segun figma es @media screen and (min-width: 1025px) { 
  pero a mi no me gusto asi que lo voy a sacar haciendo el min width enorme */
@media screen and (min-width: 10025px) {
  /* H1 - Title */
  h1 {
    font-weight: 800;
    font-size: 40px;
    line-height: 140%;
  }
  
  /* H2 - Title */
  h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
  }

  /* H3 - Title */
  h3 {
    font-weight: 400;
    font-size: 28px;
    line-height: 140%;
  }

  p {
    font-size: 20px;
  }
  
  /* Subtitle 1 */
  .subtitle-1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 140%;
  }
  
  /* Subtitle 2 */
  .subtitle-2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
  }
  
  /* Subtitle 3 */
  .subtitle-3 {
    font-weight: 700;
    /* en figma es 20 */
    font-size: 16px;
    line-height: 140%;
  }
  
  /* Body 1 */
  .body-1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
  }
  
  /* Body 2 */
  .body-2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
  
  /* Body 3 */
  .body-3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
  
  /* Button 1 */
  .button-1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }
  
  /* Button 2 */
  .button-2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
  }
}

