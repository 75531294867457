.noConnectionScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px;

  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  background: var(--maincolor);
}

.title {
  font-size: 40px;
  font-weight: bold;
  color: var(--alttextcolor);
}
