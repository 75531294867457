.discountAndDescriptionFrame {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.descriptionFrame {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 22px;
  max-height: 22px;
  margin-top: 10px;
}

.discountsHolder {
  width: 96%; /* para mantener un margen a los lados */
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start; /* eliminar esta propiedad para hacer tarjetas del mismo tamaño*/
  margin-right: 15px;
  margin-left: 15px;
  /* margin-bottom: 0px; */
}


.finishDot {
  background-color: var(--maincolor);
  border-radius: 100%;
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
  margin-top: 20px
}
