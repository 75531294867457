.discountCard {
  margin-bottom: 4px;
  max-width: 320px;
  width: 100%;
  /* min-height: 300px; */
  /* box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.2); */
  border-radius: 25px;
  background-color: var(--neutralWhite);
}

.discountHeaderFrame {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow-x: auto;
  /* width: 100%; */
  height: 80px;
  border-radius: 25px 25px 0px 0px;
  /* background-color: var(--bgimage);   */
  /* background-image: linear-gradient(to right, red , yellow); */
  background-image: linear-gradient(
    /* to bottom right */
    to right, 
    #e7e7e7 30%, 
    #d1d1d1 50%, 
    #e7e7e7 60%);

  background-size: 300%;
  animation-name: loadingBgAnimation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  z-index: 0; /* necesario para que backgroundImage se vea bien*/
}

@keyframes loadingBgAnimation {
  0% { background-position: 100% 0%}
  100%{background-position: 0% 0%}
}

.gMapPhotoInSlider {
  object-fit: cover;
  /* width: 70%; */
  margin-right: 2px;
  height: 100%;
  background-image: linear-gradient(
    /* to bottom right */
    to right, 
    #e7e7e7 30%, 
    #d1d1d1 50%, 
    #e7e7e7 60%);

  background-size: 300%;
  animation-name: loadingBgAnimation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.closeCardButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  /* background-color: transparent grey */
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--neutralWhite);
  border-radius: 100%;
  position: absolute;
  left: 6px;
  top: 6px;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(10px) grayscale(10%);
  background-size: cover;
  background-position: center;
  z-index: -1;
  
}

.logoImage {
  position: absolute;
  top: 4px;
  border-radius: 10px;
  left: calc(100% - 70px);
  width: 70px;
  height: 80%;
  object-fit: cover;
}

.discountTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--maincolor);
  font-weight: bold;
  text-align: start;
  font-size: 1rem;
  margin-bottom: 10px;
  /* border: 1px solid red; */
  max-width: 280px;
}