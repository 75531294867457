.landing-full-screen {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--backgroundBigScreen);
}

.landing-holder {
  width: 100%;
  display: flex;
  max-width: 950px;
  height: '100%';
  background-color: var(--primaryPrimary);
}

.landing-main-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px 16px;
  color: var(--neutralWhite);
  justify-content: space-between;
}

.landing-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4vh;
  /* border: 1px solid red; */
}

.landing-body {
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
}

.landing-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(100vw - 32px);
  /* border: 1px solid red; */
}

.last-filters-frame {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 380px;
  min-width: 100px;
  width: 100%;
}

.landing-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  gap: 12px;
}

.landing-sign {
  display: flex;
  margin: 20px 0px;
  flex-direction: column;
  align-items: center;
}

.last-filters-used-all {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  /* border: 4px solid yellow; */
}

.last-filters-used-single {
  display:flex;
  flex-direction: row;
  align-items: center;
  /* border: 3px solid orange; */
  width: calc(50% - 2px);
  margin-right: 4px;
}

.last-filters-used-icon {
  min-width: 40px;
  min-height: 40px;
  border-radius: 6px;
  background-color: var(--neutralWhite);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.last-filters-used-text {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
}

.last-filters-used-text-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

/* > Mobile */
@media screen and (min-width: 1040px) {
  .landing-header-font {
    font-size: 40px;
    font-weight: 800;
    color: var(--neutralWhite);
  }

  .landing-header-bywayo {
    font-size: 20px;
    font-weight: 400;
    color: var(--neutralWhite);
  }

  .landing-title {
    font-size: 55px;
    font-weight: 800;
    color: var(--neutralWhite);
  }

  .landing-description {
    font-size: 26px;
    font-weight: 400;
    color: var(--neutralWhite);
  }

  .conditions-font {
    font-size: 14px;
    font-weight: 200;
    color: var(--neutralWhite);
  }

  .landing-button-text {
    font-size: 22px;
    font-weight: 400;
    text-wrap: nowrap;
    color: var(--neutralWhite);
  }

  .title-2 {
    font-size: 36px;
    font-weight: 800;
    color: var(--neutralWhite);
  }
}

/* Mobile  */
@media screen and (max-width: 1040px) {
  .landing-header-font {
    font-size: 24px;
    font-weight: 800;
    color: var(--neutralWhite);
  }
  
  .landing-header-bywayo {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutralWhite);
  }
  
  .landing-title {
    font-size: 35px;
    font-weight: 800;
    color: var(--neutralWhite);
  }
  
  .landing-description {
    font-size: 18px;
    font-weight: 400;
    color: var(--neutralWhite);
  }
  
  .conditions-font {
    font-size: 14px;
    font-weight: 200;
    color: var(--neutralWhite);
    margin-top: -20px;
  }
  
  .landing-button-text {
    font-size: 16px;
    font-weight: 400;
    text-wrap: nowrap;
    color: var(--neutralWhite);
  }

  .title-2 {
    font-size: 26px;
    font-weight: 800;
    color: var(--neutralWhite);
  }

}