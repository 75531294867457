.locationMarker2 {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  background: lightblue;
  /* transparency */
  opacity: 0.5;
  z-index: 5;
}

.locationMarker {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: white;
  z-index: 6;

}

.pin {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: var(--primaryPrimary);
  animation: pulse 3s infinite;
  z-index: 7;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}